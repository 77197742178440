.skills {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;

	font-size: 20pt;
}

.skills > :first-child {
	flex: 1;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.skills > :first-child > span {
	font-size: 8vw;
	color: transparent;

	font-family: 'Poppins', sans-serif;

	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
}

.skills.light > :first-child > span {
	background-color: #ffffff;
	text-shadow: -1px -1px 0px #d9d9d9, 1px 1px 1px #f0f0f0;
}

.skills.dark > :first-child > span {
	background-color: #323753;
	text-shadow: -1px -1px 1px #2b2f47, 1px 1px 1px #3a3f5f;
}

.skills > :nth-child(2) {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.description {
    flex: 1;
    display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
}

.description > div {
    width: 60%;
    min-width: 60%;
    height: 80%;

	border-radius: 20px;
	overflow-y: auto;
	scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

.description.dark > div {
    background: var(--background-dark);
	box-shadow: var(--box-shadow-dark);
}

.description.light > div {
    background: var(--background-light);
	box-shadow: var(--box-shadow-light);
}

.description > div > p {
    font-family: 'Open Sans', sans-serif;
	font-size: 14pt;
	text-align: justify;
	text-justify: inter-word;
    padding-left: 60px;
	padding-right: 60px;
	
}

.sections {
	flex: 1;
	display: flex;
}

.sections > div {
    flex: 1;
    background: red;
    margin: 25px;
	border-radius: 20px;
	height: 350px;
}

.sections > div > h2{
    font-size: 27pt;
	color: transparent;

	font-family: 'Poppins', sans-serif;

	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
}

.sections > div > ul {
    list-style: none;
    font-family: 'Open Sans', sans-serif;
    margin-left: 10%;
    margin-right: 10%;
    text-align: left;
    font-size: 14pt;
}

.sections > div > ul > li::before {
    margin-bottom: 5px;
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 20px; /* Also needed for space (tweak if needed) */
    margin-left: -2em; /* Also needed for space (tweak if needed) */
}

.sections.dark > div {
    background: var(--background-dark);
	box-shadow: 10px 10px 20px #2b2f47, -10px -10px 20px #3a3f5f;;
	
}

.sections.light > div {
    background: var(--background-light);
	box-shadow: var(--box-shadow-light);
}

.sections.dark > div > h2{
	background-color: #323753;
	text-shadow: -1px -1px 0px #2b2f47, 1px 1px 2px #3a3f5f;
}

.sections.light > div > h2{
    color: #323753;
	background-color: #ffffff;
	text-shadow: -1px -1px 0px #d9d9d9, 1px 1px 1px #f0f0f0;
}

.sections.dark > div > ul > li::before {
    color: #2b2f47; /* Change the color */
    text-shadow: -1px -1px 0px #2b2f47, 1px 1px 2px #3a3f5f;
}

.sections.light > div > ul > li::before {
    color: #d9d9d9; /* Change the color */
    text-shadow: -1px -1px 0px #d9d9d9, 1px 1px 1px #f0f0f0;
}