:root {
	--contact-input-height: 5vh;
	--contact-input-height-mobile: 40px;
	--input-border-radius: 5px;
}
.contact {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;

	font-size: 18pt;
}

.contact > :first-child {
	flex: 1;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.contact > :first-child > span {
	font-size: 8vw;
	color: transparent;

	font-family: 'Poppins', sans-serif;
	background-color: #323753;
	text-shadow: -1px -1px 2px #2b2f47, 1px 1px 2px #3a3f5f;

	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
}

.contact.light > :first-child > span {
	background-color: #ffffff;
	text-shadow: -1px -1px 0px #d9d9d9, 1px 1px 1px #f0f0f0;
}

.contact.dark > :first-child > span {
	background-color: #323753;
	text-shadow: -1px -1px 1px #2b2f47, 1px 1px 1px #3a3f5f;
}

.contact > :nth-child(2) {
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;    
}

.contact > :nth-child(2) > :first-child {
	border-radius: 50px;
    width: 80%;
    

	background: var(--background-dark);
	box-shadow: var(--box-shadow-dark);

	display: flex;
	justify-content: center;
	align-items: center;
	transition: box-shadow 1s ease-in-out;
}

.contact > :nth-child(2) > button {
    margin-top: 30px;
    margin-right: 5px;
    
    border-radius: var(--input-border-radius);

    align-self: flex-end;
    width: 60px;
    height: 60px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact.dark > :nth-child(2) > button {
	color: #ffffff;
	background: linear-gradient(145deg, #363b59, #2d324b);
	box-shadow: 9px 9px 20px #282c42, -9px -9px 20px #3c4264;
}

.contact.light > :nth-child(2) > button {
	color: #323753;
	background: linear-gradient(145deg, #ffffff, #e6e6e6);
	box-shadow: 9px 9px 20px #a1a1a1, -9px -9px 20px #ffffff;
}

.contact.dark > :nth-child(2) > :first-child {
	background: var(--background-dark);
	box-shadow: var(--box-shadow-dark);
}

.contact.light > :nth-child(2) > :first-child {
	background: var(--background-light);
	box-shadow: var(--box-shadow-light);
}

.contact > :nth-child(2) > :first-child > form {
    margin: auto;
    width: 85%;
	max-width: 85%;
	display: flex;
	flex-direction: column;
}

.details {
	flex: 1;
	display: flex;
	flex-direction: row;

	flex-wrap: wrap;

	justify-content: space-between;
}

.details > input {
	-webkit-appearance: none;
	border: none;
	padding-left: 20px;
	width: 40%;
	height: var(--contact-input-height);
	border-radius: var(--input-border-radius);

	font-family: 'Open Sans', sans-serif;
	font-size: 12.5pt;
	text-align: justify;
	text-justify: auto;
	margin-bottom: 20px;
}

.contact.dark > :nth-child(2) > :first-child > form > .details > input {
	color: #ffffff;
	background: #323753;

	box-shadow: inset 10px 10px 20px #2b2f47, inset -10px -10px 20px #3a3f5f;
}

.contact.light > :nth-child(2) > :first-child > form > .details > input {
	color: #323753;
	background: #ffffff;
	box-shadow: inset 10px 10px 20px #d9d9d9, inset -10px -10px 20px #ffffff;
}

.contact > :nth-child(2) > :first-child > form > input {
	-webkit-appearance: none;

	border: none;
	height: var(--contact-input-height);
	margin-bottom: 20px;
	padding-left: 20px;

	font-family: 'Open Sans', sans-serif;
	border-radius: var(--input-border-radius);
	font-size: 12.5pt;
	text-align: justify;
	text-justify: auto;
}

.contact > :nth-child(2) > :first-child > form > textarea {
    resize: none;
	-webkit-appearance: none;

	border-radius: 10px;
	border: none;
	padding-top: 20px;
	padding-left: 20px;
	font-weight: bold;
	margin-bottom: 4vh;
	height: calc(var(--contact-input-height) * 3);

	font-family: 'Open Sans', sans-serif;
	font-size: 12pt;
	text-align: justify;
	text-justify: auto;
}

.contact > :nth-child(2) > :first-child > form > button {
	font-weight: bold;
	align-self: flex-end;
	border-radius: var(--input-border-radius);
	width: 7vh;
    height: 7vh;
    

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact.dark > :nth-child(2) > :first-child > form > input {
	color: #ffffff;
	background: #323753;
	box-shadow: inset 10px 10px 20px #2b2f47, inset -10px -10px 20px #3a3f5f;
}

.contact.light > :nth-child(2) > :first-child > form > input {
	color: #323753;
	background: #ffffff;
	box-shadow: inset 10px 10px 20px #d9d9d9, inset -10px -10px 20px #ffffff;
}

.contact.dark > :nth-child(2) > :first-child > form > textarea {
	color: #ffffff;
	background: #323753;
	box-shadow: inset 10px 10px 20px #2b2f47, inset -10px -10px 20px #3a3f5f;
}

.contact.light > :nth-child(2) > :first-child > form > textarea {
	color: #323753;
	background: #ffffff;
	box-shadow: inset 10px 10px 20px #d9d9d9, inset -10px -10px 20px #ffffff;
}

.contact.dark > :nth-child(2) > :first-child > form > button {
	color: #ffffff;
	background: linear-gradient(145deg, #363b59, #2d324b);
	box-shadow: 9px 9px 20px #282c42, -9px -9px 20px #3c4264;
}

.contact.light > :nth-child(2) > :first-child > form > button {
	color: #323753;
	background: linear-gradient(145deg, #ffffff, #e6e6e6);
	box-shadow: 9px 9px 20px #a1a1a1, -9px -9px 20px #ffffff;
}

/*RULES FOR PHONE*/
@media only screen and (max-width: 600px) {
    .contact > :nth-child(2) {
        padding-left: 8%;
        padding-right: 8%;
    }

	.contact > :nth-child(2) > :first-child {
		border-radius: 20px;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
	}

	.contact > :nth-child(2) > :first-child > form {
		width: 90%;
		max-width: 90%;
		-webkit-appearance: none;
	}

	.details > input {
		-webkit-appearance: none;

		border: none;
		padding-left: 20px;
		width: 100%;
		height: var(--contact-input-height-mobile);
		border-radius: var(--input-border-radius);

		font-family: 'Open Sans', sans-serif;
		font-size: 10pt;
		text-align: justify;
		text-justify: auto;
	}

	.contact > :nth-child(2) > :first-child > form > input {
		border: none;
		height: var(--contact-input-height-mobile);
		margin-bottom: 20px;
		padding-left: 20px;

		border-radius: var(--input-border-radius);
		font-size: 10pt;
	}

	.contact > :nth-child(2) > :first-child > form > textarea {
		font-size: 10pt;
        height: calc(var(--contact-input-height-mobile) * 3);
        margin-bottom: 0px;
	}

	.contact > :nth-child(2) > button {
        margin-top: 20px;        
        align-self: flex-end;
    }    
}

/*RULES FOR NOT-PHONE*/
@media only screen and (min-width: 600px) {
    .contact > :nth-child(2) > :first-child {
        height: 75%;
    }
}


