.about {
    width: 100%;
	height: 100%;
	display: flex;
    flex-direction: row;
    
    font-size: 20pt;
}

.about > :first-child {
	flex: 1;
	height: 100%;
	display: flex;
	justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about > :first-child > span {
    font-size: 8vw;
    color: transparent;
    
    font-family: 'Poppins', sans-serif;
    background-color: #323753;
    text-shadow: -1px -1px 2px #2b2f47, 1px 1px 2px #3a3f5f;
    
    -webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
}

.about.light > :first-child > span{
    background-color: #ffffff;
	text-shadow: -1px -1px 0px #d9d9d9, 1px 1px 1px #f0f0f0;
}

.about.dark > :first-child > span{
    background-color: #323753;
	text-shadow: -1px -1px 1px #2b2f47, 1px 1px 1px #3a3f5f;
}


.about > :nth-child(2) {
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;
}

.about > :nth-child(2) > div {
    padding-right: 5vw;
    padding-left: 5vw;
}

.about > :nth-child(2) > div > p {
    font-family: 'Open Sans', sans-serif;
    font-size: 13.5pt;
    text-align: justify;
    text-justify: auto;
}

@media only screen and (max-width: 1040px) {
	.about > :nth-child(2) > div > p {
		font-size: 10pt;
	}
}