.projects {
    width: 100%;
	height: 100%;
	display: flex;
    flex-direction: row;
    
    font-size: 20pt;
}

.projects > :first-child {
	flex: 1;
	height: 100%;
	display: flex;
	justify-content: center;
    align-items: center;
    flex-direction: column;
}

.projects > :first-child > span {
    font-size: 8vw;
    color: transparent;
    
    font-family: 'Poppins', sans-serif;
    background-color: #323753;
    text-shadow: -1px -1px 2px #2b2f47, 1px 1px 2px #3a3f5f;
    
    -webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
}

.projects.light > :first-child > span{
    background-color: #ffffff;
	text-shadow: -1px -1px 0px #d9d9d9, 1px 1px 1px #f0f0f0;
}

.projects.dark > :first-child > span{
    background-color: #323753;
	text-shadow: -1px -1px 1px #2b2f47, 1px 1px 1px #3a3f5f;
}

.projects > :last-child {
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;	
}

.projects > :last-child > :first-child {
	width: 100%;
	max-width: 100%;
	height: 60%;
	max-height: 60%;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;	
}

.projects > :last-child > :first-child > .display{ 
	height: 80%;
	width: 80%;
	border-radius: 50px;
	overflow-y: auto;
	scrollbar-color: transparent transparent;
	scrollbar-width: thin;
}

.projects > :last-child > :first-child > .display > h1{ 
	font-size: 24pt;
}

.projects > :last-child > :first-child > .display > p{ 
	font-family: 'Open Sans', sans-serif;
	font-size: 14pt;
	text-align: justify;
	text-justify: inter-word;

	padding-left: 60px;
	padding-right: 60px;
}

.projects.dark > :last-child > :first-child > .display{ 
	background: var(--background-dark);
	box-shadow: var(--box-shadow-dark);
}

.projects.light > :last-child > :first-child > .display{ 
	background: var(--background-light);
	box-shadow: var(--box-shadow-light);
}

.projects > :last-child > :last-child {
	width: 100%;
	max-height: 40%;
	flex: 1;

	flex-flow: wrap;
	display: flex;
	flex-direction: row;
	align-content: flex-start;
	justify-content: space-around;
	align-items: flex-start;
}

.projects > :last-child > :last-child > .project {
	-webkit-appearance: none;

	height: 120px;
	width: 120px;
	border-radius: 20px;
	margin: 1vw 1vw;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	cursor: pointer;
}

.projects > :last-child > :last-child > .project > div {
	width: 80px;
	height: 80px;
}


.projects > :last-child > :last-child > .project > div > div > span {
	font-size: 18pt;
}

.projects > :last-child > :last-child > .project > div > div >  h4 {
	font-size: 12pt;
	margin-top: 10px;
}

.projects > :last-child > :last-child > .project > :last-child {
	margin-bottom: 0;
}

.projects.dark > :last-child > :last-child > .project {
	background: var(--background-dark);
	box-shadow: 10px 10px 30px #2b2f47, -10px -10px 30px #3a3f5f;;
}

.projects.dark > :last-child > :last-child > .project.pressed {
	box-shadow: var(--box-shadow-pressed-dark);
}

.projects.light > :last-child > :last-child > .project {
	background: var(--background-light);
	box-shadow: var(--box-shadow-light);
}

.projects.light > :last-child > :last-child > .project.pressed {
	box-shadow: var(--box-shadow-pressed-light);
}

.project-icon {
	cursor: pointer;
}

/*RULES FOR PHONE*/
@media only screen and (max-width: 600px) {
	.projects > :last-child > :first-child > .display{ 
		height: 70vw;
		width: 100%;
		border-radius: 20px;
		margin-top: 60px;
	}

	.projects > :last-child {
		padding-left: 30px;
		padding-right: 30px;
	}

	.projects > :last-child > :last-child {
		width: 80%;
		max-height: 40%;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
	}

	.projects > :last-child > :last-child > .project {
		margin-top: 10px;
		height: 90px;
		width: 90px;
		margin-bottom: 10px;
	}

	.projects > :last-child > :last-child > .project > div > div{
		display: flex;
		align-content: center;
		justify-content: center;
	}

	.projects > :last-child > :last-child > .project > div > div >  span {
		font-size: 25pt;
		padding-top: 25%;
	}

	.projects > :last-child > :last-child > .project > div > div >  h4 {
		font-size: 10pt;
		display: none;
	}

	.projects.dark > :last-child > :last-child > .project {
		box-shadow: 10px 10px 30px #2b2f47, -10px -10px 30px #3a3f5f;;
	}

	.projects.dark > :last-child > :last-child > .project.pressed {
		box-shadow: inset 8px 8px 10px #2b2f47, inset -8px -8px 10px #3a3f5f;
	}

	.projects.light > :last-child > :last-child > .project {
		box-shadow: 8px 8px 10px #c2c2c2, -8px -8px 10px #ffffff;;
	}

	.projects.light > :last-child > :last-child > .project.pressed {
		box-shadow: inset 8px 8px 10px #d9d9d9, inset -8px -8px 10px #ffffff;
	}	
}

/*RULE FOR TABLETS*/
@media only screen and (max-width: 1200px) {
	.projects > :last-child > :last-child {
		width: 100%;
		max-height: 45%;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: space-evenly;
	}

	.projects > :last-child > :first-child > .display > h1{ 
		font-size: 14pt;
	}
	.projects > :last-child > :first-child > .display > p{ 
		font-size: 9pt;
		padding-left: 30px;
		padding-right: 30px;
	}

	.projects > :first-child {
		flex: 4;
	}

	.projects > :first-child > span {
		font-size: 60px;
	}

	.projects > :last-child{
		flex: 5;
	}
}

/*RULES FOR NOT-PHONE*/
@media only screen and (min-width: 1200px) {

}