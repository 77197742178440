.home {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.home > :first-child {
	flex: 1;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.introduction {
	height: 30vw;
	width: 35vw;
	text-align: left;
}

.introduction > .quote {
	margin-top: 2.5vw;
}

.introduction.light > span {
	color: transparent;
	text-shadow: -1px -1px 0px #d9d9d9, 1px 1px 1px #f0f0f0;
}

.introduction.dark > span {
	text-shadow: -1px -1px 1px #2b2f47, 1px 1px 2px #3a3f5f;
}

.introduction > h1 {
	font-size: 12pt;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-shadow: inset 20px 20px 60px #2b2f47, inset -20px -20px 60px #3a3f5f;

	margin-top: 20px;
	margin-bottom: 20px;
}

.introduction > span {
	font-size: 5vw;
	color: transparent;
	font-family: 'Poppins', sans-serif;

	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
}

.introduction > p {
	font-family: 'Open Sans', sans-serif;
	font-size: 2vw;
}

.introduction > h2 {
	font-size: 2vw;

	margin-top: 30px;
}

.home > :nth-child(2) {
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.home > :nth-child(2) > .logo-button {
	border-radius: 50px;
	width: 30vw;
	height: 30vw;
	background: var(--background-light);
	box-shadow: var(--box-shadow-light);

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: box-shadow 1s ease-in-out;
}

.home > :nth-child(2) > .cv-button {
	border-radius: 20px;
	background: var(--background-light);
	box-shadow: var(--box-shadow-light);
	width: 4vw;
	height: 4vw;
	align-self: flex-end;
	bottom: 1.5vw;
	margin-right: 1.5vw;
	position: absolute;

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	transition: box-shadow 1s ease-in-out;
}

.home > :nth-child(2) > .cv-button.dark {
	background: var(--background-dark);
	box-shadow: var(--box-shadow-dark);
}

.home > :nth-child(2) > .cv-button > svg {
	width: 40%;
}

.home > :nth-child(2) > .pressed {
	box-shadow: var(--box-shadow-pressed-light);
}

.home > :nth-child(2) > .logo-button > img {
	width: 75%;
	transition: width 0.1s ease-in-out;
}

.home > :nth-child(2) > .pressed > img {
	width: 72%;
}

.home > :nth-child(2) > .pressed > svg {
	width: 38%;
}

.home > :nth-child(2) > .dark {
	background: var(--background-dark);
	box-shadow: var(--box-shadow-dark);
}

.home > :nth-child(2) > .dark.pressed {
	box-shadow: var(--box-shadow-pressed-dark);
}

/*RULES FOR PHONE*/
@media only screen and (max-width: 600px) {
	.home {
		flex-direction: column-reverse;
	}

	.home > :first-child {
		display: inherit !important; /*Apply !important to overwrite*/
		height: 50%;
		max-height: 50%;

		align-items: flex-start;

		display: flex;
		justify-content: center;
	}

	.introduction {
		margin-top: -20px;
		width: 50%;
	}

	.introduction > span {
		font-size: 30pt;
	}

	.introduction > p {
		font-size: 12pt;
		width: 200px;
	}

	.home > :nth-child(2) {
		height: 50%;
		max-height: 50%;
	}

	.home > :nth-child(2) > .logo-button {
		margin-top: 15vw;
		border-radius: 25px;
		width: 60vw;
		height: 60vw;

		background: none;
		box-shadow: none;
	}
}
