:root {
	--background-light: #ffffff;
	--background-dark: #323753;

	--box-shadow-light: 17px 17px 34px #c2c2c2, -17px -17px 34px #ffffff;
	--box-shadow-dark: 17px 17px 34px #2b2f47, -17px -17px 34px #3a3f5f;
	--box-shadow-pressed-light: inset 17px 17px 34px #d9d9d9,
		inset -17px -17px 34px #ffffff;
	--box-shadow-pressed-dark: inset 17px 17px 34px #2b2f47,
		inset -17px -17px 34px #3a3f5f;

	--icons-spacing: 0px;
	--icon-size: 22px;

	--breakpoint-phone: 600px;
	--breakpoint-tablet: 960px;
}

.App {
	text-align: center;
	min-height: 100%;
	height: 100%;
	transition: background 0.5s ease;
}

header {
	height: 60px;
	width: 100%;
	position: fixed;

	display: none;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	margin-bottom: 60px;
}

header > button {
	position: absolute;
	left: 0;
	padding-left: 20px;
}

header > h1 {
	font-size: 14pt;
	text-transform: capitalize;
}

footer {
	bottom: 0;
	height: 60px;
	width: 100%;
	position: fixed;

	display: none;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

footer > nav {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-content: center;
	height: 100%;
	padding-top: 5px;
	padding-bottom: 5px;
}

footer > nav > button {
	width: 50px;
}

header.dark {
	background: #323753;
	box-shadow: 11px 11px 22px #2b2f47, -11px -11px 22px #3a3f5f;
}

header.light {
	background: #ffffff;
	box-shadow: 11px 11px 22px #e0e0e0, -11px -11px 22px #ffffff;
}

footer.dark {
	background: #323753;
	box-shadow: 0px -8px 10px #2b2f47, 0px -8px 10px #3a3f5f;
}

footer.light {
	background: #ffffff;
	box-shadow: 0px -8px 10px #e0e0e0, -0px -8px 10px #ffffff;
}

footer > nav > button.pressed {
	border-radius: 10px;
}

footer.light > nav > button.pressed {
	background: #ffffff;
	box-shadow: inset 5px 5px 15px #d9d9d9, inset -5px -5px 15px #ffffff;
}

footer.dark > nav > button.pressed {
	background: #323753;
	box-shadow: inset 5px 5px 15px #2b2f47, inset -5px -5px 15px #3a3f5f;
}

.App > .content {
	height: 100%;
	text-align: center;
	display: flex;
	overflow: hidden;
}

.made-by {
	font-size: 12pt;
	color: transparent;
	font-family: 'Poppins', sans-serif;

	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
	box-shadow: none !important;

	align-self: flex-end;
	
	position: absolute;
  background: none;
  padding: 5px;
  border-radius: 5px;
  
}

@media only screen and (max-width: 1040px) {
	.made-by {
		font-size: 10pt;
	}
}

.made-by.light {
	background-color: #ffffff;
	text-shadow: -1px -1px 0px #c2c2c2, 1px 1px 0px #ffffff;
}

.made-by.dark {
	background-color: #323753;
	text-shadow: -1px -1px 0px #2b2f47, 1px 1px 1px #3a3f5f;
}

aside {
	flex: 1;
  height: 100%;
  min-height: 100%;
	min-width: 60px;
	width: 60px;
	max-width: 60px;

	display: flex;
	flex-direction: column;
	padding-top: 20px;
	padding-bottom: 20px;

	transition: background 0.5s ease;
}

main {
	flex: 10;
  height: 100%;
  overflow-y: auto;
}

.sidebar-light {
	background: #ffffff;
	box-shadow: 11px 11px 22px #e0e0e0, -11px -11px 22px #ffffff;
}

.sidebar-dark {
	background: #323753;
	box-shadow: 11px 11px 22px #2b2f47, -11px -11px 22px #3a3f5f;
}

.sidebar-sections {
	flex: 6;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.sidebar-sections > button {
	margin-top: 15px;
	margin-bottom: 15px;
}

.icon {
	width: 100%;
	height: 40px;
	margin-top: var(--icons-spacing);
	margin-bottom: var(--icons-spacing);
	cursor: pointer;
}

.social {
	padding-top: 30px;
	padding-bottom: 30px;

	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.social > button {
	margin-top: 10px;
	margin-bottom: 10px;
}

.icon > img {
	width: var(--icon-size);
	height: var(--icon-size);
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: none;
}

button::-moz-focus-inner {
	border: 0;
}

button:focus {
	border: 0;
}

button:focus-within {
	border: 0;
}

button > a:focus {
	border: 0;
	outline: none;
}

.sidebar-icon {
	width: 100%;
	height: 20px;
	background: url('/icons/home.svg');
}

/*RULES FOR PHONE*/
@media only screen and (max-width: 600px) {
	header {
		display: flex;
	}

	footer {
		display: flex;
	}

	aside {
		display: none;
	}

	main > section > :first-child {
		display: none !important; /*Apply !important to overwrite*/
  	}
  
  .made-by {
    bottom: 70px;
    left: 10px;
    background: none;
  }
}

/*RULES FOR NOT-PHONE*/
@media only screen and (min-width: 600px) {
	.feather.light:hover {
		stroke: #00b2b3;
	}

	.feather.dark:hover {
		stroke: #00ffff;
  }
  
  main > section > :first-child {
		display: flex !important; /*Apply !important to overwrite*/
  }
  
  .made-by {
    bottom: 20px;
	  right: 20px;
  }
}
